.footer{
    width: 100%;
    min-height: 20vh;
    max-height:fit-content;
    background-color: #7F7F7F;
    position:relative;
}
.footer-container{
    width: 100%;
    height: 80%;
    display: grid;
    grid-template-columns: repeat(3, auto);
    position:relative;
}
.footer-container>*{
    padding: 30px;
}
.footer-ribbon{
    display:flex;
    flex-direction: row;
    background-color: #3A3A3A;
    height:50px;
    width:100%;
    z-index:100;
    position:relative;
    color: #6C6A6A;
    justify-content: center;
    align-items:center;
    gap:50px;
}

.footer-container .logo-container{
    align-self:center;
}


.footer-navigation{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:flex-start;
    width:50%;
}
.footer-navigation-title{
    font-weight: 600;
    font-size: 36px;
    color: #3f3f3f;
}

.footer-navigation-content{
    display: flex;
    flex-direction:column;
    align-items:start;
}
.footer-navigation-link{
    text-decoration:none;
    color: #DEDEDE;
    font-size:22px;
    font-weight:300;
    transition: 0.5s;
}
.footer-navigation-link:hover{
    color:#fec601;
}
.footer-logo-container{
    position:relative;
    width:10%;
    margin: auto 0;
    padding: 0 10px;
}

@media screen and (max-width: 720px){
    .footer-ribbon>*{
        padding:0 10px;
        font-size:12px;
    }
    .footer-navigation{
        height:100%;
    }
    .footer-navigation-title{
        font-size:20px;
    }
    .footer-navigation-link{
        font-size: 16px;
    }
    .footer .socials{
        font-size:20px;
    }
}