.popup{
    position: fixed;
    top:0;
    left:0;
    height: 100vh;
    width:100vw;
    background-color: rgba(0,0,0,0.7);
    z-index:10000;
    display:flex;
    justify-content: center;
    align-items:center;
}
.popup-inner{
    width: 40%;
    max-width: 70%;
    padding:30px;
    background-color: #fec601;
    position:relative;
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    gap: 20px;
    text-align:center;
    height: 80%
}
.popup-text{
    height:60%;

}
.popup-text,
.popup-text *{
    display:flex;
    flex-direction: column;
    justify-content:center;
}
.popup_main_text{
    font-size: 3.5em;
    font-family: 'clash display';
    color: black;
    border-bottom: 2px solid black;
    height:30%;
    line-height:0.8;
    display:flex;
    justify-content:center;
    align-items:center;
}
.popup_sub_text{
    font-size:2em;
    font-family: 'archivo';
    color: black;
    height:35%;
    line-height:1.2;
}
.popup_email_field{
    height:20px;
    width: 400px;
    border-radius: 10px 25px;
    border: 0;
    padding:15px;
    font-size:26px;
    border:2px solid black;
}
.form_fields{
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    gap: 10px;
}
.popup_status{
    width:80%;
    font-size:18px;
    height: 50px;
    padding:10px;
}
.popup_status p{
    font-size:18px;
}
.popup_close{
    position:absolute;
    right:0;
    top:0;
    margin:15px;
    width:fit-content;
    height:fit-content;
    cursor: pointer;
}
.popup_btn_container{
    width: 200px;
    height: 80px;
    position:relative;
}
.popup_button{
    padding:20px;
    background: linear-gradient(to right, #fec601 50%, black 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .2s ease-out;
    color:#fec601;
    font-family: 'clash display';
    font-weight:600;
    font-size:1.2em;
}
.popup_button:hover{
    background-position: left top;
    cursor: pointer;
    color:black
}
@media screen and (min-width: 1280px){
    .popup_button{
        width: 200px;
        height: 80px;
    }

}
@media screen and (max-width: 1280px){
    .popup_main_text{
        font-size: 2.2em;
    }
    .popup_sub_text{
        font-size:1.8em;
    }
    .popup-inner{
        width:100%;
    }
    .popup_status{
        height:20px;
    }
}
@media screen and (max-width: 720px){
    .popup-inner{
        width: 100%;
        gap:0;
    }
    .popup_main_text{
        font-size: 2.5em;
    }
    .popup_sub_text{
        font-size:1.2em;
    }
    .popup_status{
        height:20px;
        width:100%;
    }
    .popup_email_field,
    .popup_button{
        font-size: medium;
    }
    .popup_email_field{
        width:100%;
        display:block;
        height:10px;
    }
    .popup_btn_container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    }
    .form_fields{
        width:80%;
        display:flex;
        flex-direction:column;
    }
}