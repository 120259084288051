.card{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    padding:30px;
    flex-basis:25%;
    height:300px;
    width:100px;
}
.left{
    align-items:flex-start;
    text-align:left;
}
.center{
    align-items:center;
    text-align:center;
}
.right{
    align-items:flex-end;
    text-align:right;
}
.card_title{
    font-weight:600;
    height:30%;
    width:100%;
    font-size:36px;
    position:relative;
    min-width:50%;
    line-height:1;
    gap:10px;
    padding-bottom: 1rem;
    color: black;
    font-family:'clash display'
}

.card_title::after{
    content: "";
    position:absolute;
    bottom:0;
    right:0;
    height:2px;
    width:100%;
    background-color:#fec601;
}
.card_content{
    font-weight:200;
    font-size:1.2em;
    width:80%;
    justify-content:center;
    align-items:start;
    color: black ;
    font-family:'Archivo'
}

@media screen and (min-width: 1200px){
    .card{
        width:100px;
    }
}
@media screen and (max-width: 1199px){
    .card{
        width:400px;
    }
}
@media screen and (min-width: 720px) and (max-width: 959px){
    .card{
        flex-basis: 40%;
        padding: 30px 0;
    }
}
@media screen and (max-width: 719px){
    .card{
        width:80%;   
        margin:auto;
        height:45%;
        flex-basis:100%;
    }
    .card>*{
        margin:10px 30px;
    }
    .card_title{
        font-size:26px;
        max-height:20%;
    }
    .card_title>*{
        text-align:end
    }
}