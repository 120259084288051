
@keyframes slide-in {
    0% {
        transform: translateX(-100%)
    }
    100% {
        transform: translateX(200%)
    }
}
.hero{
    overflow:hidden;
    width:100%;
    height:100vh;
    position:relative;
    background: linear-gradient(#100F0F,#0F3D3E);
}
.countdown{
    z-index: 100;
    font-size:3em;
    
}
.countdown span{font-family: 'archivo'}
.hero-content{
    position:absolute;
    width:100%;
    height:100vh;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    z-index:120;
    margin:20px;
    color:#E2DCC8;
}
.hero-content h2{
    font-family: 'poppins';
    font-size:2.5rem;
    z-index:28;
    font-weight:200;
    line-height: 0.8;
    text-align:center;
    position:relative;
    width:80%;
    /* text-shadow: -2px 1px 0px white; */
    /* right:-1550px; */
}
.hero-content h1{
    font-family: 'poppins';
    text-shadow: -8px 5px 0px #76353d;
    font-size:14rem;
    /* letter-spacing: 20px; */
    z-index:-18;
    line-height:1;
    font-weight:700;
    position:relative;
    width:fit-content;
    height:fit-content;
}
.hero img{
    position:absolute;
    /* height:inherit; */
    overflow-x:hidden;
    /* width:100vw; */
    bottom:0;
}
.hero img#stars{
    z-index:5;
    overflow-x: hidden;
    width:100vw;
    z-index:20
}
.hero img#slab{
    width:20%;
}
.hero img#nebula{
    z-index:80;
    background-blend-mode: lighten;
    opacity: 0.4;
    transform:scale(2);
    width:100%;
}
.hero img#comets{
    z-index:50;
    background-blend-mode: lighten;
    opacity: 0.4;
    transform:scale(1.2);
    right:0;
}
.hero img#planet1{
    transform: scale(0.4);
    right:-15%;
    bottom:-275px;
    z-index: 50;
    mix-blend-mode: difference;
    border-radius: 50%;
    box-shadow: 35px 35px 130px black;
}
#planet_slab1{
    width:fit-content;
    position:absolute;
    width:400px;
    height:600px;
}
.hero img#planet2{
    transform: scale(0.3) rotateZ(45deg);
    bottom:100px;
    left:-15%;
    z-index:50;
    mix-blend-mode: overlay;
    border-radius: 50%;
    box-shadow: 35px 35px 130px black;
}
.hero img#planet3{
    transform: scale(0.6);
    left:15%;
    z-index:50;
    bottom:-180px;
    /* mix-blend-mode: hard-light; */
    border-radius: 50%;
    box-shadow: 35px 35px 130px black;
}
.hero img#mountains_back{
    
}
@media screen and (max-width:719px){
    .hero-content{
        margin:0;
    }
    .hero img#nebula{
        height: 100vh;
    }
    .hero img#comets{
        height: 100vh;
    }
    .hero img#stars{
        overflow-x: hidden;
        height:60vh;
        margin: auto;
    }
    .hero-content h2{
        font-size:2.5em;
        width:50%;
        line-height:0.7;
    }
    .hero-content h1{
        font-size:6em;
    }
    .hero img#planet1{
        transform: scale(0.2)
    }
    .hero img#planet2{
        transform: scale(0.3)
    }
    .hero img#planet3{
        transform: scale(0.2);
        left:-100px;
    }
}