@font-face {
    font-family: "league spartan";
    src: local("league spartan"),
    url('../../public/LeagueSpartan-Bold.otf') format("opentype")
}
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Slabo+27px&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

@keyframes zoomOut {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
    
}
@keyframes scaleOut{
    0%{
        height: 50vh;
    }
    100%{
        height:100vh;
    }
}
@keyframes slideIn {
    0% {
        transform: translateX(-100%)
    }
    100% {
        transform: translateX(0)
    }
}

.flex_row_reverse{
    flex-direction: row-reverse;
}
.flex_row{
    flex-direction: row;
}
.flex_column{
    flex-direction:column;
}
.flex_column_reverse{
    flex-direction:column-reverse;
}

.justify_items_start{
    justify-content: baseline;
}
.justify_items_center{
    justify-content: center;
}
.justify_items_endd{
    justify-content: end;
}
.justify_items_space_around{
    justify-content:space-around;
}
.justify_items_space_between{
    justify-content:space-between;
}
.justify_items_space_evenly{
    justify-content:space-evenly;
}

.justify_self_start{
    justify-self: baseline;
}
.justify_self_center{
    justify-self: baseline;
}
.justify_self_end{
    justify-self: end;
}

.align_items_start{
    align-items: start;
}
.align_items_center{
    align-items: center;
}
.align_items_endd{
    align-items: end;
}

.align_self_start{
    align-self: start;
}
.align_self_center{
    align-self: center;
}
.align_self_end{
    align-self: end;
}