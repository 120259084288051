.home_container{
    position:absolute;
    top:0;
    left:0;
    height:100vh;
    width:100vw;
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
}
.home_body{
    width:100%;
    min-height:fit-content;
    overflow-x: hidden;
}

.home_content{
    top:0;
    left:0;
    height:100%;
    width:100%;
    opacity: 1;
}
#slogan_container{
    position:relative;
    min-height:200vh;
    width:100vw;
    display:flex;
    justify-content: center;
    align-items:center;
    background: white;
    overflow:hidden;
}
.slogan_img{
    position:absolute;
    width: 400px;
    z-index:-10;
}
#slogan_inner{
    height:fit-content;
    width:fit-content;
    max-height:100%;
}
#slogan_inner h1{
    font-family: 'clash display';
    font-weight: 500;
    line-height:1;
    color: #474747;
    text-align:right;
    text-shadow: 10px 5px 0 #EAEAEA;
    width:fit-content;
    padding:20px;
}

#home_section_container{
    position:relative;
    background-position: fixed;
    background-size:cover;
    background-repeat: no-repeat;
    background: url('../../public/media/blackwhite.jpg');
    height:100%;
    width:100%;
}
.homeSection{
    height:fit-content;
    position:relative;
}
.sectionTitle,
.sectionContent{
    height:100vh;
}
.sectionTitle{
    
}