.logo-container{
    width:fit-content;
    height:fit-content;
}
.logo{
    width:80px;
    font-weight:200;
    height:80%;
}
.logo img{
    height:100px;
    width:110px;
}

@media screen and (max-width:1210px){
    .logo img{
        height:75px;
        width:80px
    }
    .logo{
        font-size:0.7em;
    }
}

@media screen and (max-width: 720px){
    .logo-container{
        position:relative;
    }
}