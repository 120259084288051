.image_card{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    flex-basis:25%;
    height:300px;
    width:30%
}
.card_image_content{
    display: flex;
    flex-direction: column;
}
.card_image{
    width:50%
}
.card_image img{
    width: 200px;
}
.left{
    align-items:flex-start;
    text-align:left;
}
.center{
    align-items:center;
    text-align:center;
}
.right{
    align-items:flex-end;
    text-align:right;
}
.card_image_name{
    font-weight:600;
    height:30%;
    font-size:1.8em;
    position:relative;
    line-height:1;
    gap:10px;
    color: black;
    font-family:'clash display';
    text-align: left;
}

.card_image_name::after{
    content: "";
    position:absolute;
    bottom:0;
    right:0;
    height:2px;
    width:100%;
    background-color:#fec601;
}
.card_image_title{
    font-weight:100;
    font-size:1.5em;
    width:80%;
    color: black ;
    font-family:'archivo';
    text-align: left;
}

@media screen and (min-width: 1200px){
    .card{
        width:800px;
    }
}
@media screen and (max-width: 1199px){
    .card{
        width:400px;
    }
}
@media screen and (min-width: 720px) and (max-width: 959px){
    .card{
        flex-basis: 40%;
        padding: 30px 0;
    }
}
@media screen and (max-width: 719px){
    .card{
        width:80%;   
        margin:auto;
        height:45%;
        flex-basis:100%;
    }
    .card-container{
        gap:40px;
    }
    .card>*{
        margin:10px 30px;
    }
    .card_body{
        font-size:14px;
    }
    .card_name{
        max-height:20%;
    }
    .card_name>*{
        text-align:end
    }
    .image_card{
        width:100%;
    }
    .card_image{
        width:40%;
    }
    .card_image_content{
        display:flex;
        flex-direction: column;
        gap: 20px;
        width:60%
    }
    .card_image img{
        width:100%;
    }
    .card_image_title{
        line-height:1;
    }
}