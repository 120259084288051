.section_2{
    display:flex;
    flex-direction:row;
    min-height:100vh;
    width:100vw;
    justify-content: start;
    align-items:center;
    gap:20px;
    position:relative;
    background-color:#F1F1F1;
}

.section_2#project{

    background-position:center;
    background-attachment: fixed;
    background-size:cover;
    /* background-color: rgba(255,255,255,0.8); */
    background-blend-mode: overlay;
}

.section_container_2{
    position:relative;
    width:100%;
    min-height:60%;
    display:flex;
    flex-direction:column;
    gap: 50px;
    justify-content: space-evenly;
    padding:10%;
}
.section_content{
    width: 40%;
    display:flex;
    flex-direction:column;
    gap: 20px;
}
.section_title{
    font-size:1.5em;
    font-family:'archivo';
    font-style:italic;
    position:relative;
    line-height:1;
    padding-bottom:10px;
    width:40%;
}
.section_title::after{
    content:'';
    position:absolute;
    bottom:0;
    left:0;
    height:4px;
    width:100%;
    background-color: #fec601;
}
.section_description{
    /* justify-content:center; */
    display:flex;
    align-items:center;
    height:fit-content;
    width:80%;
}
.section_description h1{
    font-size: 4em;
    font-family: 'clash display';
    font-weight:900;
    line-height:0.8;
}
#project h1{
    /* box-shadow: -10px 8px 0 #e2dcc8; */
}
.section_text{
    height:fit-content;
    justify-content:center;
    display:flex;
    align-items:center;
}
.section_text p{
    font-size:1.2em;
    font-weight:100;
    font-family:'archivo';
    line-height:1.5
}

.section2_children{
    width:80vw;
    align-self: center;
    justify-self:center;
}
@media screen and (max-width: 720px){
    .section_content{
        width: 100%;
    }
}