.card-container{
    display:flex;
    flex-wrap:wrap;
    flex-direction:row;
    width:100%;
    justify-content:center;
    align-items:center;
    gap: 60px;
}

@media screen and (max-width: 1199px){
    .card-container{
        flex-wrap: wrap;
        width:100%;
    }
}

@media screen and (min-width: 720px) and (max-width: 959px){
    .card-container{
        gap: 20px;
        margin:0;
    }
}
@media screen and (max-width: 719px){
    .card-container{
        margin:10px 0;
        gap: 20px;
        margin:0;
        width: 100%;
        flex-direction:column;
    }
}