.navbar-container{
    position:absolute;
    z-index:1000;
    top:0;
    width:100%;
    height:200px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    overflow:hidden;
    align-items: center;
    font-size:1em;
    font-weight:700;
}
.navbar-container>*{
    padding:50px 150px;
}
.navbar{
    width:100%;
    display:flex;
    flex-direction:row;
}
.navbar-content{
    width:100%;
    display:flex;
    flex-direction:row;
    gap: 2em;
    align-self:center;
    justify-content:end;
    text-transform: lowercase;
    padding:20px 0;
}
.navbar-item{
    /* color: #151414; */
    color: white;
    font-weight:100;
    text-decoration: none;
    font-weight:200;
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    font-family:'clash display';
    font-size: 1.5em;
}
.navbar-item::before{
    content:"";
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    height:1px;
    background-color: #fec601;
    transform: scaleX(0);
    transform-origin:right;
    transition:transform 0.5s;
}
.navbar-item:hover::before{
    transform:scaleX(1);
    transform-origin:left;
}
.socials{
    gap: 0.4em;
    justify-self:flex-end;
    /* color: #151414; */
    color: white;   
    justify-content: end;
    display:flex;
    margin-right:20px;
    font-size:1.5em;
    align-items: center;
    font-weight:900;
}
.menu-icon{
    display:none;
}

.socials *{
    transition:all 0.5s ease;
}
.socials *:hover{
    color:#fec601;
    cursor:pointer;
}
.menu-seperator{
    display:none;
}

@media screen and (max-width:1210px){
    .navbar-container{
        font-size:1.4em;
    }
}

@media screen and (max-width: 1080px) {
    .navbar-container{
        font-size:1.2em;
    }
}
@media screen and (max-width: 960px){
    .navbar-content{
        gap: 1.5em;
    }
}
@media screen and (max-width: 720px){
    .navbar-container{
        display:flex;
        flex-direction:column;
        height:fit-content;
    }
    .heading{
        display:flex;
        flex-direction:row;
        width:100%;
        height:fit-content;
        /* background-color: #BCBCBC; */
        justify-content: space-between;
        align-items:center;
        height:10vh;
    }
    .heading>*{
        padding:20px;
    }

    .menu-icon{
        position:relative;
        display:inline-block;
        font-size: 28px;
        z-index:1000;
    }
    .navbar{
        height:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        position:fixed;
        transform: translateX(-100%);
        background-color: #BCBCBC;
        transition: all 0.3s;
    }
    .clicked{
        transform: translateX(0);
    }
    .navbar-content{
        width:100%;
        height:100%;
        position:relative;
        display:flex;
        flex-direction:column;
        text-align:center;
        justify-content: center;
        align-items:center;

    }
    .navbar-item{
        font-size:2em;
        font-weight:500;
    }
    .socials{
        font-size:2em;
        margin-bottom:20px;
    }
    .logo-container{
        z-index:10000;
    }
}

/*Animation*/
.slide-text-color{
    background: linear-gradient(to right, black 50%, #DEDEDE 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 0.5s ease;
}