*{
  font-family: 'Hind Vadodara', 'Heebo', sans-serif;
  scroll-behavior: smooth;
  margin:0;
  padding:0;
}
body{
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.hero-content-container{
  display:flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items:center;
  justify-content: space-evenly;
}

.home-hero-section{
  height:100%;
  width:30%;
  display:flex;
  align-items: center;
  background-color: rgba(60, 60, 60, 1);
  justify-content: baseline;
  box-shadow: 1px -30px 40px 10px #1E1E1E;
}
.home-banner{
  border: 5px solid black;
  height:fit-content;
  width:500px;
  display:flex;
  flex-direction:column;
  align-items: center;
  padding: 3em;
  margin: 0 50px;
  box-shadow: -20px 20px;
}
.home-banner-typewriter{
  width:300px;
  height:300px;
  align-self:center;
  border:5px solid black;
  background-color:#AAF0DD;
  box-shadow: -20px 20px black;
  display: block;
  padding: 50px;
  line-height: 1;
  font-size:50px;
}
.small-text{
  color: black;
}
.large-text{
  color: #AAF0DD;
}

@media screen and (min-width: 1200px){
  .small-text{
    font-size:70px;
    line-height:0.8;
    letter-spacing: -0.5px;
    font-weight:200;
    width: 60%;
  }
  .large-text{
    font-size:120px;
    font-weight: 900;
    width: 60%;
  }

}

@media screen and (max-width: 1199px) {
  
  .large-text{
      font-weight:600;
      font-size: 10vw;
      height:fit-content;
  }
  .small-text{
      font-size:6vw;
      height: fit-content;
      line-height: 4vw;
  }
}